body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}


a.navBarLink, .navBarLink {
  color: #fff;
}

a.navBarLink:hover, .navBarLink:hover {
  text-decoration: none;
  color: #aaaa;
}

a.photoCredsButton:hover {
  background-color: #3335;
  text-decoration: none;
  font-weight: bold;
}

a.photoCredsButton:hover .photoCredsText {
  color: white;
  font-weight: bolder;
  font-size: 103%;
}

.demoButton:hover {
  background-color: #aaaa;
  text-decoration: none;
}


